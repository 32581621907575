.head_wrapper {
  background: var(--tg-theme-secondary-bg-color);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1000;
  overflow: hidden;
}
.head_wrapper button {
  height: 36px;
  padding: 0.2rem;
}
.head_wrapper button img {
  height: 30px;
  width: auto;
  position: absolute;
  margin-top: -7px;
}
.text_box {
  margin-right: 10px;
  font-family: Roboto;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 16.99px;
}
.text_address_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text_address{
  margin-right: 5px;
}
.reception_hint {
  color: var(--тихий-текст);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: var(--тихий-текст);
}


.reception_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
}


.wizard_button {
  background: #C0FF00;
}