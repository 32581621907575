.cartPopup {
  width: 100%;
  max-height: calc(100% - 3rem);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  overflow-y: scroll;
}

.cartText {
  margin: 28px 14px 28px 14px;
  font-size: 22px;
}
.noteText {
  margin: 28px 14px 10px 14px;
  font-size: 18px;
}

.orderButton {
  width: calc(100% - 2rem);
  margin: 1rem;
}