
.city_label {
  background: var(--tg-theme-secondary-bg-color);
  border-radius: 8px;
  margin-left: 16px;
  margin-top: 0px;
  padding: 0.35rem 0.75rem;
  font-size: 14px;
  width: max-content;
}

.addr_from_input {
  /* border: 2px solid var(--adm-color-border); */
  margin: 0.5rem 1rem;
  border-radius: 13px;
}

.wrapper {
  height: 100%;
}

.submit_button {
  width: calc(100% - 1rem);
  margin: 0.5rem;
  background: var(--gurmag-accent-color);
}


.receptionType {
  margin-left: 20px;
  margin-top: 28px;
  font-family: Arial;
  font-size: 23px;
  font-weight: 700;
  line-height: 26.45px;

}

.addrInput {
  border: 2px solid var(--adm-color-border);
  margin: 0 19px 1rem 19px;
  padding: 0 0.5rem;
  border-radius: 13px;
}


.slotSelect { 
}

.selectWrap {
  overflow: scroll;
  width: calc(100vw - 2rem); 
}

.payMethod {
  width: 100%; 
}
.payTodo {
  border: 2px solid var(--adm-color-border);
  padding: 0 0.5rem;
  border-radius: 13px;
  width: 100%;
  min-height: 2.5rem;
}

.detail {
  margin: 1rem;
  line-height: 2rem;
  font-size: 15px;
}
.detailRight {
  text-align: end;
}

.orderButton {
  width: calc(100% - 2rem);
  margin: 1rem;
}