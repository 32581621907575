.cartItem {
  font-size: 12px;
  line-height: 0.9;
}

.cartImg {
  width: 60px;
  height: 60px;
  border-radius: 13px;
  margin: 10px 0;
}

.PriceWeight {
  margin-top: 1.75rem;
}

.Price {
  font-size: 16px;
  font-weight: 600;
}
.hotPrice {
  color: #E42B31;
}

.Weight {
  font-size: 14px;
  margin-left: 1rem;
}
