@media screen and (min-width: 0px) { 
  .watch_story_popup {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) { 
  .watch_story_popup {
    height: 100%; 
    width: auto; 
    aspect-ratio: 733/1300;
    left: 50%;
    translate:-50%;
  }
}

.story_cover_item {
  margin: 0 1rem 0 0;
  border-radius: 13px;
  overflow: hidden;
  position: relative;
}
.story_cover_item > p {
  position: absolute;
  top: 9px;
  left: 7px;
  font-size: 11px;
  line-height: 12.89px;
  font-weight: 600;
  font-family: Roboto;
  color: var(--громкий-текст);
}

.closeBtn_relative {
  position: relative;
}
.closeBtn_icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1000;
  color: white;
  font-size: 32px;
}