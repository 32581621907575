.categories_wrapper {
  width: 100%;
  border-top-left-radius: 33px;
  border-top-right-radius: 33px;
  overflow: hidden;
}
.categories_wrapper h2 {
  margin-left: 16px;
}
.courses_list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-left: 17px;
  padding-right: 17px;
  background: var(--tg-theme-bg-color);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 100%;
}
.recomendations_list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 7px;
  padding-right: 7px;
  background: var(--tg-theme-bg-color);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
}

.course_item {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  /* background: var(--tg-theme-secondary-bg-color); */
  background: var(--gur-card-bg-color);
  /* outline: 1px solid var(--adm-color-border); */
  border-radius: 15px; /* 8px; */
  border: 3px solid var(--gur-card-bg-color);
  margin: 0px 4px 10px;
  height: 247px;
  /* 247px = 134 (image) + 6 (border) + 107 (data) | было: */
  box-sizing: border-box;
}
.recomendation_item {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--gur-card-bg-color);
  border-radius: 15px; /* 8px; */
  border: 3px solid var(--gur-card-bg-color);
  margin: 0px 4px 10px;
  height: 283px;
  box-sizing: border-box;
}

.course_item .image_text, .recomendation_item .image_text{
  position: absolute;
  top: 14px;
  left: 16px;
  right: 16px;
  color: var(--gur-card-image-text-color);
  font-size: 14.5px;
  font-weight: 600;
  line-height: 16.99px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item_top_wrapper {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  
}
.rating_wrapper {
  position: relative;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: var(--gur-card-bg-color);
}
.rating_wrapper:before,
.rating_wrapper:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px; height: 8px;
}

.rating_wrapper:before {
  right: 100%; bottom: 0;
  background-image: radial-gradient(circle at 0 0, transparent 8px, var(--gur-card-bg-color) 8.5px);
}
.rating_wrapper:after {
  left: 100%; bottom: 0;
  background-image: radial-gradient(circle at 100% 0, transparent 8px, var(--gur-card-bg-color) 8.5px);
}
.item_bottom_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rating {
  display: flex;
  padding: 5px 7px;
  align-items: center;
  cursor: pointer;
}
.rating_text {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
}
.count_text {
  margin-top: 4px;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  color: var(--gur-card-count-text-color);
}
.price_text {
  margin-top: 3px;
  font-size: 14.5px;
  font-weight: 900;
  line-height: 16.99px;
}
.title_text {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.06px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.weight_text {
  margin-top: 7px;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  color: var(--gur-card-weight-text-color);
}



.title {
  margin-top: 10px;
  color: var(--тихий-текст);
}
.course_item .title, .recomendation_item .title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.8px;
}

.course_item>img, .recomendation_item>img {
  height: 134px;
  width: auto;
  object-fit: cover;
}


.description {
  color: var(--tg-theme-hint-color);
}

.item_bady {
  padding: 0px 4px;
  position: relative;
  height: calc(100% - 130px); /* 160px */
  display: flex;
}

.item_bady .title {
  text-overflow: ellipsis;
  white-space: break-spaces;
}


@media screen and (min-width: 300px) {
  .course_item, .recomendation_item {
    width: calc(50% - 8px);
  }

  .course_item>img, .recomendation_item>img {
    height: 134px;
  }
}

@media screen and (min-width: 768px) {
  .course_item, .recomendation_item {
    width: calc(33% - 8px);
  }

  .course_item>img, .recomendation_item>img {
    height: 140px;
  }
}


@media screen and (min-width: 1200px) {
  .course_item, .recomendation_item {
    width: calc(25% - 8px);
  }

  .course_item>img, .recomendation_item>img {
    height: 165px;
  }
}

@media screen and (min-width: 1400px) {
  .course_item, .recomendation_item {
    width: calc(20% - 8px);
  }

  .course_item>img, .recomendation_item>img {
    height: 180px;
  }
}

.price_cart {
  bottom: 12px;
  width: calc(100% - 36px);
  display: flex;
  position: absolute;
  align-items:flex-end;
  justify-content: space-between;
  color: var(--громкий-текст);
}

.price_cart span {
  font-size: 24px;
  font-weight: 700;
  line-height: 0.8;
}

.price_cart>.keke {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  /* border-radius: 100px; */
  background: var(--gurmag-accent-color);
  position: relative;
  color: white;
}

.shtorka {
  position: absolute;
  top: -10px;
  width: 46px;
  height: 5px;
  background: var(--tg-theme-bg-color);
  border-radius: 100px;
  left: calc(50% - 23px);
}
.hotPrice {
  color: #E42B31;
}