.collections_wrapper {
  padding: 0.5rem 0;
}


.selection_cover_item {
  margin: 0 1rem 0 0;
  border-radius: 13px;
  overflow: hidden;
  position: relative;
}