@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}
p, h2 { margin-bottom: 0 !important; }

html, body, #root {
  color: var(--tg-theme-text-color);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  background: var(--tg-theme-secondary-bg-color);
  width: 100%;
  height: 100%;
}

h2 {
  font-family: Roboto !important;
  font-size: 19px !important;
  font-weight: 700 !important;
  line-height: 22.27px !important;
}

dl, ol, ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}

/* отключение верхней черты в инпуте */
.adm-form-item.adm-form-item-vertical .adm-list-item-content { 
  border-top: none;
}

/* v Форма на карте */
/* Space на три части*/
.gur-form .gur-space.gur-space_3 .adm-space-item {
  width: 33.3%;
}
.gur-form .gur-space.gur-space_2 .adm-space-item {
  width: 50%;
}
.gur-form .gur-space .adm-space-item {
  width: 100%;
}

.gur-form .adm-form-item.gur-form__item {
  /* margin: 0.5rem 1rem; */
  margin: 0;
  border-radius: 8px;
  min-height: 44px;
  padding: 4px 8px 0px 16px;
  border: 1px solid var(--gur-input-color);
  border-radius: 8px;
}
/* крестик */
.gur-form .adm-form-item.gur-form__item .adm-input-clear {
  padding: 1.5px;
}
.gur-form .adm-form-item.gur-form__item .adm-list-item-content {
  padding-right: 0;
}
.gur-form .adm-form-item.gur-form__item .adm-list-item-content .adm-list-item-content-main {
  padding: 0;
}
.gur-form .adm-form-item.gur-form__item .adm-list-item-content .adm-input {
  min-height: auto;
  overflow: hidden;
  /* width: 100%; */
}
.gur-form .adm-form-item.gur-form__item .adm-list-item-content .adm-input .adm-input-element {
  /* width: 100%; */
}

/* label */
.gur-form .adm-form-item .adm-list-item-title .adm-form-item-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: var(--gur-input-color);
  margin-bottom: 5px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* input */
.gur-form .adm-input-element {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: var(--громкий-текст);
   display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* ^ Форма на карте */


/* Фон картинки в карточке */
.course_item_card>.adm-image {
  cursor: pointer;
}
.course_item_card>.adm-image>.adm-image-tip {
  background: var(--gur-skeleton-bg-color)
}
.course_item_card>.adm-image>.adm-image-tip>svg>path {
  fill: var(--gur-card-bg-color);
}

/* v Модалка товара */
.item_modal {
  background: none!important;
  /* min-height: calc(100% - 50px); */
  max-height: calc(100% - 15px);
}
.item_modal_wrapper {
  display: flex;
  flex-direction: column;
  background: none;
  /* overflow-y: auto; */
  border-radius: 20px 20px 0 0;
  height: 100%;
}

@media screen and (min-width: 0px) { 
  .item_modal_wrapper{
    background: var(--tg-theme-bg-color);
  }
  .item_modal_image_wrapper {
    overflow: hidden;
    height: 263px;
    max-height: 33vh;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .item_modal_image_item { 
    width: 100% !important; 
    height: 263px !important; 
  }
 }
@media screen and (min-width: 768px) { 
  .item_modal_image_wrapper { 
    width: 100% !important; 
    height: 100% !important; 
    max-height: 100% !important; 
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
    border-top-left-radius: 1rem;
  }
  .item_modal_content {
    border-bottom-right-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .item_modal_description_wrapper {
    border-bottom-right-radius: 1rem !important;
  }
  .item_modal_top_wrapper {
    border-top-right-radius: 1rem !important;
  }
  .item_modal_image_item { 
    width: 100% !important; 
    height: 100% !important; 
  }
  .item_modal_wrapper{
    background: none;
  }
 }

.item_modal_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--tg-theme-secondary-bg-color);
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.item_modal_top_wrapper {
  background: var(--tg-theme-bg-color);
  padding: 0 16px 20px;
  border-radius: 0px 0px 15px 15px;
}
.item_modal_description_wrapper {
  flex: 1;
  margin-top: 6px;
  padding: 0 16px 20px;
  border-radius: 15px 15px 0px 0px;
  background: var(--tg-theme-bg-color);
}

.item_modal_title_text {
  color: var(--громкий-текст);
  color: var(--tg-theme-text-color);
  font-size: 26px;
  font-weight: 600;
  line-height: 29px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
}
.item_modal_count_text {
  font-size: 13px;
  font-weight: 700;
  line-height: 29px;
  color: var(--gur-card-count-text-color);
}
.item_modal_weight_text {
  font-size: 22.5px;
  font-weight: 700;
  line-height: 26.37px;
  color: var(--gur-card-weight-text-color);
}
.item_modal_price_text_shell {
  padding: 1px 11.25px 2px 14.75px;
  background-color: var(--tg-theme-secondary-bg-color);
  border-radius: 10px;
}
.item_modal_price_text {
  color: var(--громкий-текст);
  font-size: 25px;
  font-weight: 800;
  line-height: 29.3px;
  color: var(--gur-card-price-text-color);
}

.item_modal_description_label {
  padding-top: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 29px;
  color: var(--тихий-текст);
}
.item_modal_description_text {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: var(--громкий-текст);
  height: 100%;
}
.item_modal_description_text > p {
  text-indent: 22px;
  margin-top: 3px;
}

.item_modal_stepper.adm-stepper {
  background-color: var(--tg-theme-secondary-bg-color);
  min-width: 112px;
}
.item_modal_stepper.adm-stepper .adm-input-element {
  font-weight: 700;
}
.item_modal_absolute_wrapper {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.item_modal_rating_wrapper {
  position: relative;
  height: 25px;
  margin-right: 19px;
  margin-left: 19px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: var(--tg-theme-bg-color);
}
.item_modal_rating_wrapper:before,
.item_modal_rating_wrapper:after {
  content: '';
  display: block;
  position: absolute;
  width: 8px; height: 8px;
}
.item_modal_rating_wrapper:before {
  right: 100%; bottom: 0;
  background-image: radial-gradient(circle at 0 0, transparent 8px, var(--tg-theme-bg-color) 8.5px);
}
.item_modal_rating_wrapper:after {
  left: 100%; bottom: 0;
  background-image: radial-gradient(circle at 100% 0, transparent 8px, var(--tg-theme-bg-color) 8.5px);
}
.item_modal_rating {
  display: flex;
  padding: 4.54px 9.19px 2.76px 8.43px;
  align-items: center;
  cursor: pointer;
}
.item_modal_rating_text {
  margin-left: 6.39px;
  font-size: 16.16px;
  font-weight: 400;
  line-height: 18.94px;
}
.item_modal_exit_btn svg>g {
  stroke-width: 3!important;
  stroke: var(--громкий-текст);
}
/* ^ Модалка товара */

.adm-scroll-mask-right {
  background: linear-gradient(to left, var(--tg-theme-secondary-bg-color), rgba(255, 255, 255, 0)) !important;
}
.adm-scroll-mask-left {
  background: linear-gradient(to right, var(--tg-theme-secondary-bg-color), rgba(255, 255, 255, 0)) !important;
}
.adm-capsule-tabs-tab-active {
  color: white !important;
  background-color: rgba(44, 46, 58, 1) !important;
}

.header_capsule .adm-capsule-tabs-tab:not(.adm-capsule-tabs-tab-active) {
  background: var(--tg-theme-bg-color) !important;
}
.header_capsule .adm-capsule-tabs-tab {
  padding: 10px 14px !important;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 700;
  line-height: 14.95px;
}
.tCenter {
  text-align: center;
}

.orderDetailForm .adm-list-body, .orderDetailForm .slot_select {
  background: var(--cart-bg-color);
}

.asdfg {
  margin-left: 19px;
}

.asdfg label {
  text-align: center;
  font-family: Arial;
  font-size: 14.5px;
  font-weight: 400;
  line-height: 16.67px;
  margin-bottom: 5px;
}

.asdfg .adm-list-item, .asdfg .adm-list-item-content {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 0px) {
  .mediaPopup, .cartPopup, .categoryPopup {
    height: 100%;
    max-height: 100%;
  }
  .superRow {
    max-height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .superCol {
    flex: 1;
    height: 1px;
  }
 }
@media screen and (min-width: 768px) {
  .cartPopup {
    height: 75vh;
    border-radius: 25px;
    overflow: hidden;
  }
  .mediaPopup {
    height: 75vh;
    min-width: 75vw;
    border-radius: 15px;
    overflow: hidden;
  }
  .categoryPopup {
    max-height: 100vh;
    height: auto;
    min-height: 50vh;
    min-width: 75vw;
    border-radius: 15px;
    overflow: hidden;
  }
  .superRow {
    max-height: 100%;
    flex-direction:initial;
    justify-content:initial;
  }
  .superCol {
    flex: 1;
    height: 100%;
  }
 }