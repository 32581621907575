.custom_button_wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
}
.custom_button {
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  /* height: 33px; */
  /* width: 215px; */
  border-radius: 20px;
  font-size: 14.5px;
  font-weight: 400;
  /* line-height: 16.99px; */
  text-align: center;
  background: var(--gurmag-accent-color);
/*   margin-left: 14px;
  margin-right: 14px; */
  align-self: center;
  cursor: pointer;
}

.info_block_wrapper {
  position: relative;
  /* max-width: 360px; */
  width: 100%;
  /* height: 114px; */
}
.info_block_close {
  position: absolute;
  top: 0;
  right: -5px;
  cursor: pointer;
}
.info_block_row {
  display: flex;
  flex-direction: row;
}
.info_block_image {
  margin-top: 3px;
}
.info_block_data {
  margin-left: 21px;
  margin-right: 25px; /* ? */
  height: 69px;
}
.info_block_title {
  font-size: 14.5px;
  font-weight: 700;
  line-height: 16.99px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info_block_text {
  font-size: 14.5px;
  font-weight: 400;
  line-height: 16.99px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}